/* entire container, keeps perspective */
.flip-container {
  perspective: 1000;
}
/* flip the pane when hovered */
.flip-container.flip-this .flipper {
  transform: rotateX(180deg);
}

/* flip speed goes here */
.flipper {
  transition: 1s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}
